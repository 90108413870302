<template>
  <div style="position: relative;">
    <div class="loader-wrap" v-if="loading==true">
      <div class="loader"></div>
    </div>
    <div v-else-if="loading==!true">
      <div class="header-additional">
        <div class="container">
          <div class="header-additional-block">
            <div  class="header-additional__logo">
              <img src="images/qabilet_logo.svg" width="70" height="30">
              <div class="header-additional__desc">
                {{ $t('Qabilet.заголовок') }}
              </div>
            </div>
            <div  class="header-additional__selects" style="width: unset;">
              <div class="select select--region">
                <el-select
                    @change="updateInfo()"
                    :placeholder="$t('Qabilet.Регион')"
                    v-model="region_id"
                    filterable>
                  <el-option
                      key="0"
                      :label="$t('Qabilet.ПоказатьВсе')"
                      value="0">
                  </el-option>
                  <el-option
                      v-for="item in regions"
                      :key="item.id+1"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="select select--date select--region">
                <el-date-picker
                    @change="updateInfo()"
                    v-model="qabiletDateFrom"
                    class="select--date"
                    type="date"
                    placeholder=""
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <div class="select select--date select--region">
                <el-date-picker
                    @change="updateInfo()"
                    v-model="qabiletDateTo"
                    class="select--date"
                    type="date"
                    placeholder=""
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                />
              </div>
              <el-select
                  @change="changeLang()"
                  :placeholder="$t('Qabilet.ВыберитеЯзык')"
                  v-model="locale"
                  filterable>
                <el-option
                    key="kz"
                    :label="$t('Qabilet.Казахский')"
                    value="kz">
                </el-option>
                <el-option
                    key="ru"
                    :label="$t('Qabilet.Русский')"
                    value="ru">
                </el-option>
              </el-select>
              <div style="margin-left: 2rem;margin-right: 0" class="header-additional__date">
                <div class="current-date mr-4">
                  {{ date }}
                </div>
                <div class="current-time">
                  {{ time }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="statistic">
        <div class="container">
          <div class="title">
            {{ $t('Qabilet.СтатистикаВЦифрах') }}
          </div>
          <div class="statistic__rows">
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.ЦентровQabilet') }}</div>
                <div class="statistic-card__amount">{{ digits.centers | Numerize }}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.РуководителейЦентровQabilet') }}</div>
                <div class="statistic-card__amount">{{ digits.center_managers | Numerize }}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.ТренеровЦентровQabilet') }}</div>
                <div class="statistic-card__amount">{{ digits.center_coach | Numerize }}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.ПсихологовЦентровQabilet') }}</div>
                <div class="statistic-card__amount">{{ digits.center_psychologists | Numerize}}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.ПсихологовШкол') }}</div>
                <div class="statistic-card__amount">{{ digits.school_psychologists | Numerize}}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.ПсихологовДетСадов') }}</div>
                <div class="statistic-card__amount">{{ digits.kindergarten_psychologists | Numerize}}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.УчастниковТестирования') }}</div>
                <div class="statistic-card__amount">{{ digits.test_participants | Numerize}}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.Дошкольник') }}</div>
                <div class="statistic-card__amount">{{ digits.kindergarten_users | Numerize}}</div>
              </div>
              <div class="statistic-card">
                <div class="statistic-card__title">{{ $t('Qabilet.Родитель') }}</div>
                <div class="statistic-card__amount">{{ digits.parents | Numerize}}</div>
              </div>
            </div>
        </div>
      </section>
      <section class="stat-consultations">
        <div class="container">
          <div class="title">
            {{ $t('Qabilet.КоличествоОбученныхПсихологовПроекта') }}
          </div>
          <div class="stat-consultations__cards">
            <div class="stat-consultations__card">
              <div class="stat-consultations__amount">
                {{ seriesSchool[0]+seriesSchool[1] | Numerize}}
              </div>
              <div class="stat-consultations__desc">
                {{ $t('Qabilet.КоличествОобученныхПсихологовШкол') }}
              </div>
            </div>
            <div class="stat-consultations__card">
              <div class="stat-consultations__amount">

                {{ seriesKinderGarten[0]+seriesKinderGarten[1]| Numerize }}
              </div>
              <div class="stat-consultations__desc">
                {{ $t('Qabilet.КоличествоОбученныхПсихологовДетскихСадов') }}

              </div>
            </div>
          </div>

          <div class="stat-consultations__cards" style="justify-content: center;">
            <apexcharts class="donut-chart-qabilet" type="donut" :options=" chartOptionsSchool" width="500" height="450" :series="seriesSchool"></apexcharts>
            <apexcharts class="donut-chart-qabilet" type="donut" :options="chartOptionsKindergarten" width="500" height="450" :series="seriesKinderGarten"></apexcharts>


          </div>


        </div>
      </section>

      <section class="stat-regions">
        <div class="container">
          <div class="title">
            {{ $t('Qabilet.КоличествоПользователейПрошедшихТестированиеВРазрезеРегионов') }}

          </div>
          <div class="stat-regions__row">
            <div class="stat-regions__chart">
              <div id="chart">
                <apexcharts ref="regionChart" width="100%" height="482px" type="bar" :options="options"
                            :series="seriesTests"></apexcharts>
              </div>
            </div>
            <div class="stat-regions__card">
              <div class="stat-regions__amount">
                {{ regionAllCount | Numerize}}
              </div>
              <div class="stat-regions__amount-desc">
                {{ $t('Qabilet.ПрошедшихТестирование') }}
              </div>
              <ol class="stat-regions__list">
                <div style="cursor: pointer!important;margin-bottom: 12px;font-size: 14px;line-height: 16px;}"
                     @click="chartFilterRegion()"
                     v-bind:class="{ 'stat-regions__active-item': activeChartRegion==false }">
                  {{ $t('Qabilet.ПоказатьВсе') }}
                </div>
                <li @click="chartFilterRegion(item.region.id)"
                    v-bind:class="{ 'stat-regions__active-item': activeChartRegion==item.region.id }"
                    v-for="item in regionsChart" :key="item.region.id+2">{{ item.region.name }}
                </li>

              </ol>
            </div>
          </div>
        </div>
      </section>

      <section class="stat-age">
        <div class="container">
          <div class="title">
            {{ $t('Qabilet.СтатистикаВРазрезеРазделовТестированияИВозрастныхКатегорий') }}
          </div>
          <div class="stat-age__table-block">
            <div class="stat-age__region">
              {{ $t('Qabilet.диагностика1шага') }}
            </div>
            <table class="table stat-age__table">
              <thead>
              <tr>
                <th scope="col">{{ $t('Qabilet.пол') }}</th>
                <th v-for="(item,index) in Object.keys(diagnostic1Gender1)" :key="index+3" scope="col">{{
                    item | Numerize
                  }}
                  {{ $t('Qabilet.лет') }}
                </th>
                <th scope="col">{{ $t('Qabilet.итого') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('Qabilet.мужской') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic1Gender1)" :key="index+4">{{ item | Numerize}}</td>
                <td>{{ tableDiagnostic.diagnostic_1.gender1All | Numerize}}</td>

              </tr>
              <tr>
                <th scope="row">{{ $t('Qabilet.женский') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic1Gender2)" :key="index+5">{{ item | Numerize }}</td>
                <td>{{ tableDiagnostic.diagnostic_1.gender2All | Numerize }}</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th scope="col">{{ $t('Qabilet.ЯзыкСдачи') }}</th>
                <th v-for="(item,index) in Object.keys(diagnostic1LangKz)" :key="index+6" scope="col">{{ item  | Numerize}}
                  {{ $t('Qabilet.лет') }}
                </th>
                <th>{{ $t('Qabilet.итого') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('Qabilet.Казахский') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic1LangKz)" :key="index+7">{{ item | Numerize }}</td>
                <td>{{ tableDiagnostic.diagnostic_1.langKzAll | Numerize }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t('Qabilet.Русский') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic1LangRu)" :key="index+8">{{ item | Numerize }}</td>
                <td>{{ tableDiagnostic.diagnostic_1.langRuAll | Numerize }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="stat-age__table-block">
            <div class="stat-age__region">
              {{ $t('Qabilet.диагностика2шага') }}
            </div>
            <table class="table stat-age__table">
              <thead>
              <tr>
                <th scope="col">{{ $t('Qabilet.пол') }}</th>
                <th v-for="(item,index) in Object.keys(diagnostic2Gender1)" :key="index+9" scope="col">{{
                    item | Numerize
                  }}
                  {{ $t('Qabilet.лет') }}
                </th>
                <th scope="col">{{ $t('Qabilet.итого') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('Qabilet.мужской') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic2Gender1)" :key="index+10">{{ item | Numerize }}</td>
                <td>{{ tableDiagnostic.diagnostic_2.gender1All | Numerize}}</td>

              </tr>
              <tr>
                <th scope="row">{{ $t('Qabilet.женский') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic2Gender2)" :key="index+11">{{ item | Numerize }}</td>
                <td>{{ tableDiagnostic.diagnostic_2.gender2All | Numerize }}</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th scope="col">{{ $t('Qabilet.ЯзыкСдачи') }}</th>
                <th v-for="(item,index) in Object.keys(diagnostic2LangKz)" :key="index+12" scope="col">{{ item | Numerize}}
                  {{ $t('Qabilet.лет') }}
                </th>
                <th>{{ $t('Qabilet.итого') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('Qabilet.Казахский') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic2LangKz)" :key="index+13">{{ item | Numerize }}</td>
                <td>{{ tableDiagnostic.diagnostic_2.langKzAll | Numerize}}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t('Qabilet.Русский') }}</th>
                <td v-for="(item,index) in Object.values(diagnostic2LangRu)" :key="index+14">{{ item | Numerize}}</td>
                <td>{{ tableDiagnostic.diagnostic_2.langRuAll  | Numerize}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="stat-age__table-block">
            <div class="stat-age__region">
              {{ $t('Qabilet.Профориентация') }}
            </div>
            <table class="table stat-age__table">
              <thead>
              <tr>
                <th scope="col">{{ $t('Qabilet.пол') }}</th>
<!--                <th scope="col">5-6 {{ $t('Qabilet.лет') }}</th>-->
<!--                <th scope="col">7-9 {{ $t('Qabilet.лет') }}</th>-->
<!--                <th scope="col">10-11 {{ $t('Qabilet.лет') }}</th>-->
<!--                <th scope="col">12-14 {{ $t('Qabilet.лет') }}</th>-->
                <th scope="col">14-17 {{ $t('Qabilet.лет') }}</th>
                <th scope="col">{{ $t('Qabilet.итого') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('Qabilet.мужской') }}</th>
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th scope="row">{{ $t('Qabilet.женский') }}</th>
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
                <td>0</td>
                <td>0</td>
              </tr>
              </tbody>
              <thead>
              <tr>
                <th scope="col">{{ $t('Qabilet.ЯзыкСдачи') }}</th>
<!--                <th scope="col">5-6 {{ $t('Qabilet.лет') }}</th>-->
<!--                <th scope="col">7-9 {{ $t('Qabilet.лет') }}</th>-->
<!--                <th scope="col">10-11 {{ $t('Qabilet.лет') }}</th>-->
<!--                <th scope="col">12-14 {{ $t('Qabilet.лет') }}</th>-->
                <th scope="col">14-17 {{ $t('Qabilet.лет') }}</th>
                <th scope="col">{{ $t('Qabilet.итого') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('Qabilet.Казахский') }}</th>
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th scope="row">{{ $t('Qabilet.Русский') }}</th>
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
<!--                <td>0</td>-->
                <td>0</td>
                <td>0</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
<!--      <section class="table-section container">-->
<!--        <div class="title mb-3">{{ $t('manager-profile.ТаблицаДошкольников') }}</div>-->
<!--        <div class="table-wrap" >-->
<!--          <div class="table-filter">-->
<!--            <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.region_id" class="table-filter-4" :placeholder="$t('profile.consultations.region')"-->
<!--                       @change="getCitiesPreschoolers">-->
<!--              <el-option-->
<!--                  v-for="(item,index) in regions"-->
<!--                  :key="index+15"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.city_id" class="table-filter-4" :placeholder="$t('profile.consultations.district')" @change="getLocalitiesPreschoolers">-->
<!--              <el-option-->
<!--                  v-for="(item,index) in citiesPreschoolers"-->
<!--                  :key="index"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.locality_id" class="table-filter-4"-->
<!--                       :placeholder="$t('profile.consultations.city')" @change="getKindergartenPreschoolers">-->
<!--              <el-option-->
<!--                  v-for="(item,index) in localitiesPreschoolers "-->
<!--                  :key="index+17"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-select :disabled="preschoolersLoad"  @change=" preschoolersFilter" v-model="preschoolersFilt.kindergarten" class="table-filter-4" :placeholder="$t('manager-profile.детскийCад')">-->
<!--              <el-option-->
<!--                  v-for="(item,index) in kindergatenPreschoolers"-->
<!--                  :key="index+18"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.age" class="table-filter-4" :placeholder="$t('manager-profile.возраст')"  @change=" preschoolersFilter">-->
<!--              <el-option-->
<!--                  v-for="(age,index) in ages"-->
<!--                  :key="index+19"-->
<!--                  :label="age.name"-->
<!--                  :value="age.val">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-date-picker-->
<!--                :disabled="preschoolersLoad"-->
<!--                @change="preschoolersFilter"-->
<!--                v-model="preschoolersFilt.yearFrom"-->
<!--                class="table-filter-8"-->
<!--                type="date"-->
<!--                :placeholder="$t('manager-profile.датаОт')"-->
<!--                format="yyyy.MM.dd"-->
<!--                value-format="yyyy.MM.dd"-->
<!--            />-->
<!--            <el-date-picker-->
<!--                :disabled="preschoolersLoad"-->
<!--                @change="preschoolersFilter"-->
<!--                v-model="preschoolersFilt.yearTo"-->
<!--                class="table-filter-8"-->
<!--                type="date"-->
<!--                :placeholder="$t('manager-profile.датаДо')"-->
<!--                format="yyyy.MM.dd"-->
<!--                value-format="yyyy.MM.dd"-->
<!--            />-->
<!--            <button :disabled="preschoolersLoad" @click="clearTable('preschoolers')" class="table-filter-4 btn-filter-clear">{{$t('references.reset-btn')}}</button>-->
<!--          </div>-->
<!--          <div v-show="preschoolersLoad==false" class="customScroll">-->
<!--            <table  style="text-align: center" class="table stat-age__table">-->
<!--              <thead>-->

<!--              <th>-->
<!--                №-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('profile.iin-label')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('profile.fio')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('profile.birth-date-label')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('references.gender')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('profile.consultations.region')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('profile.consultations.district')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('profile.consultations.city')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('manager-profile.детскийCад')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('manager-profile.фиоРодителя')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('manager-profile.телефонРодителя')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('manager-profile.подтверждениeЛичности')}}-->
<!--              </th>-->
<!--              <th>-->
<!--                {{$t('manager-profile.комментарий')}}-->
<!--              </th>-->
<!--              <th> {{$t('main-page.test-results')}}</th>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr v-for="(item,index) in preschoolers" :key="index+20">-->
<!--                <td>-->
<!--                  {{index+1}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{item.iin}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{item.fullname}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{item.birth_date.split('-').join('.')}}-->
<!--                </td>-->
<!--                <td>{{item.gender==1?$t('references.gender-male'):$t('references.gender-female')}}</td>-->
<!--                <td><span v-if="item.region">{{item.region.name}}</span></td>-->
<!--                <td><span v-if="item.city">{{item.city.name}}</span></td>-->
<!--                <td><span v-if="item.locality">{{item.locality.name}}</span></td>-->
<!--                <td><span v-if="item.kindergarten">{{item.kindergarten.name}}</span></td>-->
<!--                <td><span v-if="item.parent">{{item.parent.fullname}}</span></td>-->
<!--                <td><span v-if="item.parent">{{item.parent.phone | Phone}}</span></td>-->
<!--                <td><span >{{item.confirmation}}</span></td>-->
<!--                <td><span >{{item.comment}}</span></td>-->
<!--                <td>-->
<!--                  <a :href="API_ROOT+'/storage/'+item.diagnostic" download-->
<!--                     style="display: block" class="btn-filter-clear">-->
<!--                    {{ $t('test-results.download-file') }}-->
<!--                  </a></td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--          <div class="pagination-lk-wrapper">-->
<!--            <el-pagination-->
<!--                v-show="preschoolersLoad==false"-->
<!--                :current-page='preschoolersMeta.current_page'-->
<!--                @current-change="paginatePreschoolers"-->
<!--                layout="prev, pager, next"-->
<!--                :page-count="preschoolersMeta.last_page" >-->
<!--            </el-pagination>-->

<!--          </div>-->
<!--        </div>-->
<!--      </section>-->
      <section class="stat-consultations">
        <div class="container">
          <div class="title">
            {{ $t('Qabilet.СтатистикаПоКонсультациям') }}
          </div>
          <div class="stat-consultations__cards">
            <div class="stat-consultations__card">
              <div class="stat-consultations__amount">
                {{ psychsReq.center_psychologist_requests | Numerize}}
              </div>
              <div class="stat-consultations__desc">
                {{ $t('Qabilet.ПолучившихКонсультацийПсихологамиЦентров') }}
              </div>
            </div>
            <div class="stat-consultations__card">
              <div class="stat-consultations__amount">
                {{ psychsReq.school_psychologist_request| Numerize }}
              </div>
              <div class="stat-consultations__desc">
                {{ $t('Qabilet.ПолучившихКонсультацийПсихологамиШкол') }}
              </div>
            </div>
          </div>

          <div class="stat-regions">
            <div class="stat-regions__row">
              <div class="stat-regions__chart">
                <div id="chart">
                  <apexcharts ref="regionChart" width="100%" height="482px" type="bar" :options="optionsNoStacked"
                              :series="seriesPsych"></apexcharts>
                </div>
              </div>
              <div class="stat-regions__card">

                <div class="stat-regions__amount-desc">
                  {{$t('Qabilet.регионы')}}
                </div>

                <ol class="stat-regions__list">
                  <div style="cursor: pointer!important;margin-bottom: 12px;font-size: 14px;line-height: 16px;}"
                       @click="chartFilterRegionPsych()"
                       v-bind:class="{ 'stat-regions__active-item':  activeChartRegionPsych==false }">
                    {{ $t('Qabilet.ПоказатьВсе') }}
                  </div>
                  <li @click="chartFilterRegionPsych(item.region.id)"
                      v-for="(item,index) in regionsChartPsych" :key="index+21"
                      v-bind:class="{ 'stat-regions__active-item':  activeChartRegionPsych==item.region.id }"
                  >{{ item.region.name }}
                  </li>

                </ol>
              </div>
            </div>
          </div>
<!--          <div class="title">-->
<!--           Запросы на смену детского сада-->
<!--          </div>-->
<!--          <div class="stat-regions">-->
<!--            <div class="stat-regions__row">-->
<!--              <div class="stat-regions__chart">-->
<!--                <div id="chart">-->
<!--                  <apexcharts  width="100%" height="482px" type="bar" :options="options"-->
<!--                              :series="seriesKinderGartenChart"></apexcharts>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="stat-regions__card">-->
<!--                <div class="stat-regions__amount-desc">-->
<!--                  {{$t('Qabilet.регионы')}}-->
<!--                </div>-->
<!--                <ol class="stat-regions__list">-->
<!--                  <div style="cursor: pointer!important;margin-bottom: 12px;font-size: 14px;line-height: 16px;}"-->
<!--                       @click="chartFilterKinderGarten()"-->
<!--                       v-bind:class="{ 'stat-regions__active-item':  kindergartenCurrentRegion==false }">-->
<!--                    {{ $t('Qabilet.ПоказатьВсе') }}-->
<!--                  </div>-->
<!--                  <li @click="chartFilterKinderGarten(item.id)"-->
<!--                      v-for="item in regions" :key="item.id+22"-->
<!--                      v-bind:class="{ 'stat-regions__active-item':   kindergartenCurrentRegion==item.id }"-->
<!--                  >{{ item.name }}-->
<!--                  </li>-->
<!--                </ol>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'qabilet',
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      citiesPreschoolers:{},
      localitiesPreschoolers:{},
      kindergatenPreschoolers:{},
      paginatePreschoolers:{},
      API_ROOT:window.API_ROOT,
      seriesKinderGartenChart:[],
      kindergartenCurrentRegion:false,
      seriesKinderGarten:[],
      seriesSchool:[],
      chartOptionsKindergarten: {
        colors: ['#10A2D3', '#04C2F4'],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: 'Количество Психологов Детских садов, прошедших обучение от тренера',
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        labels: ['на русском языке', 'на казахском языке'],
      },
      chartOptionsSchool: {
        colors: ['#10A2D3', '#04C2F4'],
        legend: {
          show: true,
          position: 'bottom',

        },
        chart: {
          height: 350,
          type: 'donut',
        },
        title: {
          text: 'Количество Психологов Школ, прошедших обучение от тренера',
          align: 'center',
          margin: 35,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            color: '#0F3D5F'
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: '#0F3D5F',
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: this.$t('МедальЕлбасы.Всего'),
                  color: '#0F3D5F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        labels: ['на русском языке', 'на казахском языке'],
      },
      time: '',
      date: '',
      loading: true,
      optionsNoStacked: {
        chart: {

          type: 'bar',
          height: 350,
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {},
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            show: false,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '7px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }},
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        },
        colors: ['#343F68', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
      options: {
        chart: {

          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {},
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          labels: {
            show: false,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '7px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }},
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        },
        colors: ['#343F68', '#7CDEFF', '#04C2F4', '#10A2D3'],
        fill: {
          opacity: 1
        },
      },
      seriesTests: [],
      seriesPsych: [],
      regionsChart: {},
      regionsChartPsych: {},
      konsultChart: {},
      locale: this.$i18n.locale,
      psychsReq: {},
      qabiletDateTo: '',
      qabiletDateFrom: '',
      region_id: '',
      regions: [],
      digits: {},
      psychs: {},
      activeChartRegion: false,
      activeChartRegionPsych: false,
      regionAllCount: 0,
      regionAllCountPsych: 0,
      tableDiagnostic: {},
      diagnostic1Gender1:{},
      diagnostic1Gender2:{},
      diagnostic1LangRu:{},
      diagnostic1LangKz:{},
      diagnostic2Gender1:{},
      diagnostic2Gender2:{},
      diagnostic2LangRu:{},
      diagnostic2LangKz:{},
      ages: [
        {val:  'filter[age][from]=5&filter[age][to]=6', name: '5-6'},
        {val:  'filter[age][from]=7&filter[age][to]=9', name: '7-9'},
        {val:  'filter[age][from]=10&filter[age][to]=11', name: '10-11'},
      ],
      preschoolersFilt: {
        age: '',
        region_id: '',
        city_id: '',
        locality_id: '',
        kindergarten: '',
        yearFrom: '',
        yearTo: '',
      },
      preschoolers:[],
      preschoolersMeta:[],
      showKinderGartenChart:false,
      preschoolersLoad:true,
      loadingApi: {
        regions: true,
        digits: true,
        psychs: true,
        psychsReq: true,
        regionsChart: true,
        psychologistRequests: true,
        dashboardAges: true,
      }

    }
  },
  filters: {
    Numerize: function (value) {
      if (value==0) return '0'
      if (!value) return ''
      if(value<=999) return value
      return value.toLocaleString().replace(',',' ')
    }
  },
  mounted() {
    this.loadingApi.regions = true
    this.$http.get(window.API_ROOT + '/api/references/regions/')
        .then((res) => {
          this.regions = res.body.data;
          this.options.xaxis.categories=[]
          this.optionsNoStacked.xaxis.categories=[]
          for(var i = 0; i<res.body.data.length; i++){
            this.optionsNoStacked.xaxis.categories.push(res.body.data[i].name)
            this.options.xaxis.categories.push(res.body.data[i].name)
          }
          this.loadingApi.regions = false
        })
    setInterval(this.clock, 1000);
    this.qabiletDateFrom = new Date(new Date().setYear(new Date().getFullYear() - 1))
    this.qabiletDateTo = new Date()
    var dd = this.qabiletDateFrom.getDate();
    var mm = this.qabiletDateFrom.getMonth() + 1;
    var yyyy = this.qabiletDateFrom.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    this.qabiletDateFrom = yyyy + '-' + mm + '-' + dd;

    dd = this.qabiletDateTo.getDate();
    mm = this.qabiletDateTo.getMonth() + 1;
    yyyy = this.qabiletDateTo.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    this.qabiletDateTo = yyyy + '-' + mm + '-' + dd;
    if (this.region_id == 0) {
      this.region_id = ''
    }
    this.qabiletDateFrom=''
    this.qabiletDateTo=''
    this.updateInfo()
  },
  watch: {
    'loadingApi': {
      handler(newValue, oldValue) {
        this.loading = !Object.values(this.loadingApi).every(elem => elem == false)

      },
      deep: true
    }
  },
  methods: {
    chartFilterKinderGarten(item=false){
      this.showKinderGartenChart=false
      this.kindergartenCurrentRegion=item
      this.seriesKinderGartenChart=[]
      if(item==false){
        this.$http.get(window.API_ROOT + `/api/dashboard/kindergarten-psychologists-statistic?&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
            .then((res) => {

              var aprovedArray=[]
              var declinedArray=[]
              for(var i=0; i<this.regions.length;i++){
                aprovedArray.push(0)
                declinedArray.push(0)
              }
              for(var x=0; x<res.body.kindergarten_change_requests.approved.length;x++){
                aprovedArray[res.body.kindergarten_change_requests.approved[x].region_id-1]=res.body.kindergarten_change_requests.approved[x].count
              }
              for(x=0; x<res.body.kindergarten_change_requests.declined.length;x++){
                declinedArray[res.body.kindergarten_change_requests.declined[x].region_id-1]=res.body.kindergarten_change_requests.declined[x].count
              }
              this.seriesKinderGartenChart.push(
                  {
                    'name': 'Отклонено',
                    'data':  declinedArray
                  })
              this.seriesKinderGartenChart.push(
                  {
                    'name': 'Принято',
                    'data':  aprovedArray
                  })

              this.showKinderGartenChart=true
            })
      }
      else{
        this.$http.get(window.API_ROOT + '/api/dashboard/kindergarten-psychologists-statistic?filter[region_id]='+item+`&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
            .then((res) => {
              var aprovedArray=[]
              var declinedArray=[]
              for(var i=0; i<this.regions.length;i++){
                aprovedArray.push(0)
                declinedArray.push(0)
              }
              for(var x=0; x<res.body.kindergarten_change_requests.approved.length;x++){
                aprovedArray[res.body.kindergarten_change_requests.approved[x].region_id-1]=res.body.kindergarten_change_requests.approved[x].count
              }
              for(x=0; x<res.body.kindergarten_change_requests.declined.length;x++){
                declinedArray[res.body.kindergarten_change_requests.declined[x].region_id-1]=res.body.kindergarten_change_requests.declined[x].count
              }
              this.seriesKinderGartenChart.push(
                  {
                    'name': 'Отклонено',
                    'data':  declinedArray
                  })
              this.seriesKinderGartenChart.push(
                  {
                    'name': 'Принято',
                    'data':  aprovedArray
                  })
              this.showKinderGartenChart=true
            })
      }
    },
    clock() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      this.date = dd + '.' + mm + '.' + yyyy;
      var date = new Date(),
          hours = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours(),
          minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes(),
          seconds = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
      this.time = hours + ':' + minutes + ':' + seconds;
    },
    changeLang() {
      this.loading = true
      localStorage.setItem('locale', this.locale);
      this.$i18n.locale = this.locale;
      this.$router.go()
    },

    chartFilterRegion(item = false) {
      function sum(x) {
        var s = 0;
        for (var z = 0; z < x.length; z++) {
          s += x[z]
        }
        return s
      }

      this.activeChartRegion = item


      var seriesTests = []
      var profOrientation = []
      var kindergarten = []
      var step1 = []
      var step2 = []

      if (item == false) {
        for (var i = 0; i < this.regionsChart.length; i++) {
          profOrientation.push(this.regionsChart[i].prof_orientation)
          kindergarten.push(this.regionsChart[i].diagnostic_for_kindergarten_users)
          step1.push(this.regionsChart[i].diagnostic)
          step2.push(this.regionsChart[i].second_step_diagnostic)
        }
      }
      if (item != false) {
        item = item - 1
        for (var x = 0; x < this.regionsChart.length; x++) {
          if (x == item) {
            profOrientation.push(this.regionsChart[item].prof_orientation)
            kindergarten.push(this.regionsChart[item].diagnostic_for_kindergarten_users)
            step1.push(this.regionsChart[item].diagnostic)
            step2.push(this.regionsChart[item].second_step_diagnostic)
          } else {
            profOrientation.push(0)
            kindergarten.push(0)
            step1.push(0)
            step2.push(0)
          }

        }
      }

      seriesTests.push(
          {
            'name': this.$t('Qabilet.Профориентация'),
            'data': profOrientation
          })
      seriesTests.push(
          {
            'name': this.$t('Qabilet.Диагностикадошкольного возраста'),
            'data': kindergarten
          })
      seriesTests.push(
          {
            'name': this.$t('Qabilet.диагностика1шага'),
            'data': step1
          })
      seriesTests.push(
          {
            'name': this.$t('Qabilet.диагностика2шага'),
            'data': step2
          })


      this.seriesTests = seriesTests
      this.regionAllCount = sum(profOrientation) + sum(kindergarten) + sum(step1) + sum(step2)

    },

    chartFilterRegionPsych(item = false) {
      function sum(x) {
        var s = 0;
        for (var z = 0; z < x.length; z++) {
          s += x[z]
        }
        return s
      }

      this.activeChartRegionPsych = item

      var seriesPsych = []
      var center_psychologist_finished = []
      var center_psychologist_new = []
      var school_psychologist_finished = []
      var school_psychologist_new = []

      if (item == false) {
        for (var i = 0; i < this.regionsChartPsych.length; i++) {
          center_psychologist_finished.push(this.regionsChartPsych[i].center_psychologist_finished)
          center_psychologist_new.push(this.regionsChartPsych[i].center_psychologist_new)
          school_psychologist_finished.push(this.regionsChartPsych[i].school_psychologist_finished)
          school_psychologist_new.push(this.regionsChartPsych[i].school_psychologist_new)
        }
      }
      if (item != false) {
        item = item - 1
        for (var x = 0; x < this.regionsChartPsych.length; x++) {
          if (x == item) {
            center_psychologist_finished.push(this.regionsChartPsych[item].center_psychologist_finished)
            center_psychologist_new.push(this.regionsChartPsych[item].center_psychologist_new)
            school_psychologist_finished.push(this.regionsChartPsych[item].school_psychologist_finished)
            school_psychologist_new.push(this.regionsChartPsych[item].school_psychologist_new)
          } else {
            center_psychologist_finished.push(0)
            center_psychologist_new.push(0)
            school_psychologist_finished.push(0)
            school_psychologist_new.push(0)
          }

        }
      }

      seriesPsych.push(
          {
            'name': this.$t('Qabilet.ЗаписаноНаКонсультациюКПсихологуЦентраQabilet'),
            'data': center_psychologist_new
          })
      seriesPsych.push(
          {
            'name': this.$t('Qabilet.ОказаноКонсультацийПсихологомЦентраQabilet'),
            'data': center_psychologist_finished
          })
      seriesPsych.push(
          {
            'name': this.$t('Qabilet.ЗаписаноНаКонсультациюКПсихологуШколы'),
            'data': school_psychologist_new
          })
      seriesPsych.push(
          {
            'name': this.$t('Qabilet.ОказаноКонсультацийПсихологомШколы'),
            'data': school_psychologist_finished
          })


      this.seriesPsych = seriesPsych
      this.regionAllCountPsych = sum(center_psychologist_new) + sum(center_psychologist_finished) + sum(school_psychologist_new) + sum(school_psychologist_finished)

    },
    clearTable(table){
      if(table=='preschoolers'){
        this.preschoolersLoad=true
        this.$http.get(window.API_ROOT + '/api/preschoolers')
            .then((res) => {
              this.localitiesPreschoolers=[]
              this.citiesPreschoolers=[]
              this.kindergatenPreschoolers=[]
              this.preschoolersFilt.age=''
              this.preschoolersFilt.region_id=''
              this.preschoolersFilt.city_id=''
              this.preschoolersFilt.locality_id=''
              this.preschoolersFilt.kindergarten=''
              this.preschoolersFilt.yearFrom=''
              this.preschoolersFilt.yearTo=''
              this.preschoolers = res.body.data
              this.preschoolersMeta=res.body.meta
              this.preschoolersLoad=false
            })
      }
    },
    getKindergartenPreschoolers(id){
      this.preschoolersFilter()
      this.$http.get(window.API_ROOT + '/api/references/kindergartens/' + id)
          .then((res) => {
            this.preschoolersFilt.kindergarten=''
            this.kindergatenPreschoolers = res.body.data;
          })
    },
    getCitiesPreschoolers(id){
      this.preschoolersFilter()
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.preschoolersFilt.city_id=''
            this.preschoolersFilt.locality_id=''
            this.preschoolersFilt.kindergarten=''
            this.localitiesPreschoolers=''
            this.kindergatenPreschoolers =''
            this.citiesPreschoolers = res.body.data;
          })},
    getLocalitiesPreschoolers(id){
      this.preschoolersFilter()
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.preschoolersFilt.locality_id=''
            this.preschoolersFilt.kindergarten=''
            this.kindergatenPreschoolers =''
            this.localitiesPreschoolers = res.body.data;
          })},
    preschoolersFilter() {
      if(this.preschoolersFilt.yearFrom==null){
        this.preschoolersFilt.yearFrom=''
      }
      if(this.preschoolersFilt.yearTo==null){
        this.preschoolersFilt.yearTo=''
      }
      this.preschoolersLoad=true
      this.$http.get(window.API_ROOT + `/api/preschoolers?${this.preschoolersFilt.age!=false? this.preschoolersFilt.age : 'filter[age][from]=&filter[age][to]=' }&filter[region_id]=${this.preschoolersFilt.region_id}&filter[city_id]=${this.preschoolersFilt.city_id}&filter[locality_id]=${this.preschoolersFilt.locality_id}&filter[kindergarten]=${this.preschoolersFilt.kindergarten}&filter[years][from]=${this.preschoolersFilt.yearFrom}&filter[years][to]=${this.preschoolersFilt.yearTo}`)
          .then((res) => {
            this.preschoolers = res.body.data
            this.preschoolersMeta=res.body.meta
            this.preschoolersLoad=false
          })
    },
    async updateInfo() {
      this.loading = true
      if (this.qabiletDateTo == null) {
        this.qabiletDateTo = ''
      }
      if (this.qabiletDateFrom == null) {
        this.qabiletDateFrom = ''
      }
      this.$http.get(window.API_ROOT + '/api/preschoolers')
          .then((res) => {
            this.preschoolers = res.body.data
            this.preschoolersMeta=res.body.meta
            this.preschoolersLoad=false
          })
      this.loadingApi.regions = true
      this.$http.get(window.API_ROOT + `/api/dashboard/kindergarten-psychologists-statistic?date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            var aprovedArray=[]
            var declinedArray=[]
            this.seriesKinderGartenChart=[]
            this.seriesSchool=[]
            this.seriesKinderGarten=[]
            this.seriesKinderGarten=[]
            for(var i=0; i<this.regions.length;i++){
                aprovedArray.push(0)
                declinedArray.push(0)
            }
            for(var x=0; x<res.body.kindergarten_change_requests.approved.length;x++){
              aprovedArray[res.body.kindergarten_change_requests.approved[x].region_id-1]=res.body.kindergarten_change_requests.approved[x].count
            }
            for(x=0; x<res.body.kindergarten_change_requests.declined.length;x++){
              declinedArray[res.body.kindergarten_change_requests.declined[x].region_id-1]=res.body.kindergarten_change_requests.declined[x].count
            }
            this.seriesKinderGartenChart.push(
                {
                  'name': 'Отклонено',
                  'data':  declinedArray
                })
            this.seriesKinderGartenChart.push(
                {
                  'name': 'Принято',
                  'data':  aprovedArray
                })
            this.seriesSchool.push(res.body.trained_school_ps.ru)
            this.seriesSchool.push(res.body.trained_school_ps.kz)
            this.seriesKinderGarten.push(res.body.trained_kindergarten_ps.ru)
            this.seriesKinderGarten.push(res.body.trained_kindergarten_ps.kz)
          })
      this.$http.get(window.API_ROOT + '/api/references/regions/')
          .then((res) => {
            this.regions = res.body.data;
            this.loadingApi.regions = false
          })
      this.loadingApi.digits = true
      this.$http.get(window.API_ROOT + `/api/dashboard/digits?region_id=${this.region_id}&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            this.digits = res.body.data
            this.loadingApi.digits = false
          })
      this.loadingApi.psychsReq = true
      this.$http.get(window.API_ROOT + `/api/dashboard/psychologists?region_id=${this.region_id}&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            this.psychs = res.body.data
            this.loadingApi.psychsReq = false
          })
      this.loadingApi.psychs = true
      this.$http.get(window.API_ROOT + `/api/dashboard/psychologist-requests?region_id=${this.region_id}&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            this.psychsReq = res.body.data
            this.loadingApi.psychs = false
          })
      this.loadingApi.regionsChart = true
      this.$http.get(window.API_ROOT + `/api/dashboard/regions?region_id=${this.region_id}&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            function sum(x) {
              var s = 0;
              for (var z = 0; z < x.length; z++) {
                s += x[z]
              }
              return s
            }

            this.regionsChart = Object.values(res.body.data)
            var seriesTests = []
            var profOrientation = []
            var kindergarten = []
            var step1 = []
            var step2 = []
            for (var i = 0; i < this.regionsChart.length; i++) {
              profOrientation.push(this.regionsChart[i].prof_orientation)
              kindergarten.push(this.regionsChart[i].diagnostic_for_kindergarten_users)
              step1.push(this.regionsChart[i].diagnostic)
              step2.push(this.regionsChart[i].second_step_diagnostic)
            }
            seriesTests.push(
                {
                  'name': this.$t('Qabilet.Профориентация'),
                  'data': profOrientation
                })
            seriesTests.push(
                {
                  'name': this.$t('Qabilet.Диагностикадошкольного возраста'),
                  'data': kindergarten
                })
            seriesTests.push(
                {
                  'name': this.$t('Qabilet.диагностика1шага'),
                  'data': step1
                })
            seriesTests.push(
                {
                  'name': this.$t('Qabilet.диагностика2шага'),
                  'data': step2
                })


            this.seriesTests = seriesTests
            this.regionAllCount = sum(profOrientation) + sum(kindergarten) + sum(step1) + sum(step2)
            this.loadingApi.regionsChart = false
          })
      this.loadingApi.psychologistRequests = true
      this.$http.get(window.API_ROOT + `/api/dashboard/psychologist-requests/regions?region_id=${this.region_id}&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            function sum(x) {
              var s = 0;
              for (var z = 0; z < x.length; z++) {
                s += x[z]
              }
              return s
            }

            this.regionsChartPsych = Object.values(res.body.data)
            var seriesPsych = []
            var center_psychologist_finished = []
            var center_psychologist_new = []
            var school_psychologist_finished = []
            var school_psychologist_new = []


            for (var i = 0; i < this.regionsChartPsych.length; i++) {
              center_psychologist_finished.push(this.regionsChartPsych[i].center_psychologist_finished)
              center_psychologist_new.push(this.regionsChartPsych[i].center_psychologist_new)
              school_psychologist_finished.push(this.regionsChartPsych[i].school_psychologist_finished)
              school_psychologist_new.push(this.regionsChartPsych[i].school_psychologist_new)
            }


            seriesPsych.push(
                {
                  'name': this.$t('Qabilet.ЗаписаноНаКонсультациюКПсихологуЦентраQabilet'),
                  'data': center_psychologist_new
                })
            seriesPsych.push(
                {
                  'name': this.$t('Qabilet.ОказаноКонсультацийПсихологомЦентраQabilet'),
                  'data': center_psychologist_finished
                })
            seriesPsych.push(
                {
                  'name': this.$t('Qabilet.ЗаписаноНаКонсультациюКПсихологуШколы'),
                  'data': school_psychologist_new
                })
            seriesPsych.push(
                {
                  'name': this.$t('Qabilet.ОказаноКонсультацийПсихологомШколы'),
                  'data': school_psychologist_finished
                })


            this.seriesPsych = seriesPsych
            this.regionAllCountPsych = sum(center_psychologist_new) + sum(center_psychologist_finished) + sum(school_psychologist_new) + sum(school_psychologist_finished)
            this.loadingApi.psychologistRequests = false
          })
      this.loadingApi.dashboardAges = true
      this.$http.get(window.API_ROOT + `/api/dashboard/ages?region_id=${this.region_id}&date_from=${this.qabiletDateFrom}&date_to=${this.qabiletDateTo}`)
          .then((res) => {
            function sum(x) {
              var s = 0;
              for (var z = 0; z < x.length; z++) {
                s += x[z]
              }
              return s
            }

            this.tableDiagnostic = res.body.data


            this.diagnostic1Gender1={
              '5-6':res.body.data.diagnostic_1.gender_1[5]+res.body.data.diagnostic_1.gender_1[6],
              '7-9':res.body.data.diagnostic_1.gender_1[7]+res.body.data.diagnostic_1.gender_1[8]+res.body.data.diagnostic_1.gender_1[9],
              '10-11':res.body.data.diagnostic_1.gender_1[10]+res.body.data.diagnostic_1.gender_1[11],
            }
            this.diagnostic1Gender2={
              '5-6':res.body.data.diagnostic_1.gender_2[5]+res.body.data.diagnostic_1.gender_2[6],
              '7-9':res.body.data.diagnostic_1.gender_2[7]+res.body.data.diagnostic_1.gender_2[8]+res.body.data.diagnostic_1.gender_2[9],
              '10-11':res.body.data.diagnostic_1.gender_2[10]+res.body.data.diagnostic_1.gender_2[11],
            }
            this.diagnostic1LangRu={
              '5-6':res.body.data.diagnostic_1.lang_ru[5]+res.body.data.diagnostic_1.lang_ru[6],
              '7-9':res.body.data.diagnostic_1.lang_ru[7]+res.body.data.diagnostic_1.lang_ru[8]+res.body.data.diagnostic_1.lang_ru[9],
              '10-11':res.body.data.diagnostic_1.lang_ru[10]+res.body.data.diagnostic_1.lang_ru[11],
            }
            this.diagnostic1LangKz={
              '5-6':res.body.data.diagnostic_1.lang_kz[5]+res.body.data.diagnostic_1.lang_kz[6],
              '7-9':res.body.data.diagnostic_1.lang_kz[7]+res.body.data.diagnostic_1.lang_kz[8]+res.body.data.diagnostic_1.lang_kz[9],
              '10-11':res.body.data.diagnostic_1.lang_kz[10]+res.body.data.diagnostic_1.lang_kz[11],
            }
            this.diagnostic2Gender1={
              '7-9':res.body.data.diagnostic_2.gender_1[7]+res.body.data.diagnostic_2.gender_1[8]+res.body.data.diagnostic_2.gender_1[9],
              '10-11':res.body.data.diagnostic_2.gender_1[10]+res.body.data.diagnostic_2.gender_1[11],
            }
            this.diagnostic2Gender2={
              '7-9':res.body.data.diagnostic_2.gender_2[7]+res.body.data.diagnostic_2.gender_2[8]+res.body.data.diagnostic_2.gender_2[9],
              '10-11':res.body.data.diagnostic_2.gender_2[10]+res.body.data.diagnostic_2.gender_2[11],
            }
            this.diagnostic2LangRu={
              '7-9':res.body.data.diagnostic_2.lang_ru[7]+res.body.data.diagnostic_2.lang_ru[8]+res.body.data.diagnostic_2.lang_ru[9],
              '10-11':res.body.data.diagnostic_2.lang_ru[10]+res.body.data.diagnostic_2.lang_ru[11],
            }
            this.diagnostic2LangKz={
              '7-9':res.body.data.diagnostic_2.lang_kz[7]+res.body.data.diagnostic_2.lang_kz[8]+res.body.data.diagnostic_2.lang_kz[9],
              '10-11':res.body.data.diagnostic_2.lang_kz[10]+res.body.data.diagnostic_2.lang_kz[11],
            }
            this.tableDiagnostic.diagnostic_1.gender1All =res.body.data.diagnostic_1.gender_1.all
            this.tableDiagnostic.diagnostic_1.gender2All =res.body.data.diagnostic_1.gender_2.all
            this.tableDiagnostic.diagnostic_2.gender1All =res.body.data.diagnostic_2.gender_1.all
            this.tableDiagnostic.diagnostic_2.gender2All =res.body.data.diagnostic_2.gender_2.all
            this.tableDiagnostic.diagnostic_1.langKzAll = res.body.data.diagnostic_1.lang_kz.all
            this.tableDiagnostic.diagnostic_1.langRuAll = res.body.data.diagnostic_1.lang_ru.all
            this.tableDiagnostic.diagnostic_2.langKzAll = res.body.data.diagnostic_2.lang_kz.all
            this.tableDiagnostic.diagnostic_2.langRuAll = res.body.data.diagnostic_2.lang_ru.all
            this.loadingApi.dashboardAges = false
          })
    }
  }
}
</script>
<style lang="css" scoped>
.header-additional__logo{
  width: 37%;
}
.header-additional__selects{
  width: 70%;
}
.loader {
  background: conic-gradient(#04C2F4, transparent) !important;
}
.statistic__rows{
  display: flex;
  flex-wrap: wrap;
}
.statistic-card{
  width: 19%;
  margin: 10px auto;
}
@media (max-width: 1424px) {
 .statistic-card{
   min-width: 30%;
 }
}
@media (max-width: 991px) {

.header-additional__selects{
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.header-additional__selects .select--date{
  margin-bottom: 1rem;
}
.header-additional__selects .el-select{
  width: 181px;
}
  .header-additional__logo{
    display: none;
  }
  .header-additional__desc{
    display: none;
  }
  .stat-regions__chart{
    width: 100%;
  }
  .stat-regions__row{
    flex-direction: column;
  }
  .stat-consultations__card{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .donut-chart-qabilet{
    margin: auto;
  }
  .statistic-card{
    min-width: 45%;
  }
  .stat-consultations__cards{
    flex-direction: column!important;
  }
  .stat-regions__card{
    margin-top: 1rem;
    margin-left: 0;
    width: 100%;
  }
}

</style>
